<template>
    <div class="vue-box">

        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>项目实施</el-breadcrumb-item>
                <el-breadcrumb-item>意见反馈</el-breadcrumb-item>
                <el-breadcrumb-item>反馈详情</el-breadcrumb-item>
            </el-breadcrumb>

        </div>

        <div class="title">反馈详情</div>
        <div class="c-panel">
            <el-descriptions :column="1">
                <el-descriptions-item label="客户名称">{{one.creatorName}}</el-descriptions-item>
                <el-descriptions-item label="问题描述">

                    {{one.contente}}</el-descriptions-item>
                <el-descriptions-item label="图片">
                    <el-image style="width:120px; height:180px" :src="one.imageFile[0]? one.imageFile[0].url: require('@/static/img/no.png') " :preview-src-list="[one.imageFile[0]? one.imageFile[0].url:require('@/static/img/no.png')]">
                    </el-image>
                </el-descriptions-item>

                <el-descriptions-item label="联系方式" :span="2">
                    {{one.mobile}}
                </el-descriptions-item>
            </el-descriptions>
        </div>

        <!--表单提交-->
        <div class="c-panel">
            <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                <el-form-item label="状态">
                    <input-enum enumName="feedBackStateEnum" v-model="m.state"></input-enum>
                </el-form-item>

                <el-form-item label="解决说明">
                    <el-input type="textarea" rows="3" placeholder="解决说明" v-model="m.content" style="width: 400px;"></el-input>
                </el-form-item>

                <el-form-item>
                    <span class="c-label">&emsp;</span>
                    <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <!--时间线-->
        <div class="title">历史记录</div>
        <div class="c-panel">
            <el-timeline :reverse="true">
                <el-timeline-item v-for="activity in feedbackLogs" :key="activity.id" :timestamp="activity.createTime">
                    {{activity.content}} <strong>解决进度：{{activity.stateEnum}}</strong>
                </el-timeline-item>
            </el-timeline>
        </div>
    </div>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: {
        inputEnum
    },
    data() {
        return {
            one: {},
            m: { feedbackId: 0, state: "", content: "" },
            feedbackLogs: [],
            rules: {
                feedbackId: [],
                image: [],
                content: [],
                state: []
            }
        };
    },

    methods: {
        //提交建议反馈信息
        ok: function(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.sa.post("/feedback/saveLog", this.m).then(res => {
                        console.log(res);
                        this.f5();
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        f5() {
            this.sa.get("/feedback/log/" + this.one.id).then(res => {
                this.feedbackLogs = res.data;
                console.log("时间线数据又了呀", this.feedbackLogs);
            });
        }
    },

    created() {
        this.one = this.sa_admin.params;
        this.one.imageFile = JSON.parse(this.one.image);
        this.one.avatarFile = JSON.parse(this.one.avatar);

        console.log("接受只拉拉拉....", this.one);
        this.m.feedbackId = this.one.id;
        this.f5();
    }
};
</script>

<style>
.header {
    margin: 20px;
}

.title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
    margin-left: 20px;
}
</style>